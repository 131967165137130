import { IconType, defaultProps } from './types';

/**
 * Icono Chat
 */
export const Cutlery = ({
    className,
    width,
    height,
    color = defaultProps.color,
}: IconType): JSX.Element => (
    <svg
        {...(className ? { className: className } : {})}
        {...(width ? { width: width } : {})}
        {...(height ? { height: height } : {})}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <defs
            id="defs6" />
        <g
            id="g8"
            transform="matrix(0.20345052,0,0,0.20345052,0.03387869,1.2220526e-6)">
            <g
                id="g1641">
                <path
                    style={{ fill: color, fillOpacity: "1", strokeWidth: "0.96" }}
                    d="M 91.573018,489.69141 C 85.067196,485.72427 82.154316,478.75273 82.347711,467.612 82.546579,456.15588 95.8435,210.42907 96.456603,206.88 c 0.442521,-2.56163 0.04627,-3.16518 -3.584815,-5.45966 -9.465169,-5.9811 -18.866931,-17.74713 -24.019995,-30.06034 -9.979088,-23.84496 -9.979088,-54.87504 0,-78.720002 C 74.751435,78.542846 85.925729,65.517087 96.413307,60.511827 129.06974,44.92635 162.74552,74.339185 166.99646,122.16 c 0.77609,8.73064 0.0352,24.60286 -1.44998,31.06539 -5.65973,24.62643 -17.89229,42.90365 -33.90596,50.66044 l -4.19949,2.03417 0.54639,7.68 c 0.30052,4.224 3.67586,64.37149 7.50077,133.66109 6.72105,121.75465 6.90037,126.15081 5.34512,131.04 -1.72191,5.41313 -6.13183,10.8668 -9.96573,12.32446 -1.29392,0.49194 -9.99245,0.89283 -19.33008,0.89085 -14.977697,-0.003 -17.329342,-0.21813 -19.964482,-1.82499 z"
                    id="path827" />
                <path
                    style={{ fill: color, fillOpacity: "1", strokeWidth: "0.96" }}
                    d="m 234.54636,488.75156 c -4.32433,-3.13253 -6.92288,-7.87542 -7.8444,-14.31765 -0.34598,-2.41872 1.82537,-49.78383 4.82523,-105.25579 2.99985,-55.47197 5.76256,-107.60142 6.13934,-115.84324 l 0.68506,-14.98511 -9.61686,-0.46308 c -10.75268,-0.51778 -15.42108,-2.47112 -18.04838,-7.55175 -0.7849,-1.51784 -3.36642,-12.98493 -5.73672,-25.48244 L 200.64,182.12976 V 136.1372 c 0,-50.533196 -0.12565,-49.386255 5.8784,-53.661517 5.43446,-3.869669 14.52607,-2.213172 18.20051,3.316141 1.10971,1.669906 1.44973,10.558488 1.84109,48.128176 0.5227,50.17907 0.42627,49.3568 6.01613,51.30544 3.31502,1.15562 6.66964,0.0159 8.76699,-2.97846 1.35553,-1.93529 1.53688,-7.44745 1.53688,-46.71551 0,-39.088614 0.19015,-44.922031 1.55835,-47.805294 4.4729,-9.42594 16.32004,-10.137832 22.9761,-1.380631 1.77791,2.339155 1.87882,4.624353 2.14825,48.647225 l 0.28269,46.19277 2.34949,2.20723 c 3.05484,2.86987 7.43874,2.86672 10.49827,-0.008 l 2.35752,-2.21478 0.27466,-46.18521 c 0.25459,-42.809873 0.40232,-46.373291 2.02136,-48.758523 2.70022,-3.977897 6.41863,-5.951503 11.2133,-5.951503 4.79468,0 8.51309,1.973606 11.2133,5.951628 1.63284,2.40554 1.74389,5.70248 1.7036,50.573298 l -0.0431,48 -4.43983,21.98782 c -2.98272,14.77164 -5.04942,22.80573 -6.29725,24.48 -3.53897,4.74837 -7.53279,6.24969 -17.71314,6.65859 -7.22627,0.29025 -9.38359,0.68606 -9.38359,1.72167 0,0.73962 2.83728,51.98938 6.30504,113.88834 3.46777,61.89896 6.11687,115.46779 5.88687,119.04183 -0.32312,5.02124 -1.04705,7.50752 -3.18503,10.93853 -4.60214,7.3855 -6.67433,8.00321 -26.84785,8.00321 h -17.39094 z"
                    id="path1143" />
                <path
                    style={{ fill: color, fillOpacity: "1", strokeWidth: "0.96" }}
                    d="m 378.85498,489.02568 c -5.49748,-3.73564 -8.2738,-10.42465 -8.28561,-19.96257 -0.005,-4.16728 2.60141,-55.75475 5.79237,-114.63884 3.19098,-58.88408 5.59616,-107.59775 5.34487,-108.2526 -0.25128,-0.65484 -1.83062,-1.49235 -3.50964,-1.86112 -3.69427,-0.8114 -9.91278,-6.22459 -11.30496,-9.84092 -1.88258,-4.89023 -8.65417,-34.23294 -10.65953,-46.18995 -3.22998,-19.25887 -2.79091,-28.79299 2.76986,-60.14729 C 369.40035,69.503461 379.26212,31.380448 389.04381,12 394.04032,2.1004232 396.3091,0 402.00567,0 c 6.16024,0 13.92738,3.8614997 17.07022,8.4866064 l 2.3641,3.4790916 V 122.74953 c 0,108.86956 -0.0329,110.83718 -1.90766,113.87051 l -1.90768,3.08668 6.2897,112.70663 c 3.45933,61.98865 6.11098,115.83375 5.89255,119.65578 -0.31841,5.5713 -0.94933,7.83532 -3.18204,11.41853 -4.61811,7.41151 -6.68849,8.03233 -26.78704,8.03233 -17.05898,0 -17.3658,-0.0365 -20.98284,-2.49431 z"
                    id="path1145" />
            </g>
        </g>
    </svg>
);
