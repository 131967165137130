import { FunctionComponent } from 'react';
import { TypeRecipeFeaturesProps } from './types';
import { ChefHat, Clock, Cutlery } from "components/icons";

export const RecipeFeatures: FunctionComponent<TypeRecipeFeaturesProps> = ({
  difficulty,
  servings,
  time
}: TypeRecipeFeaturesProps) => (
  <div className="flex w-auto font-manrope-semibold">
    <div className="flex flex-col gap-2 items-center min-w-[80px]">
      <div className="flex justify-center items-center bg-neutral-2 rounded-2xl w-10 h-10">
        <ChefHat color='rgb(var(--tw-color-primary-500))' />
      </div>
      <div>{difficulty}</div>
    </div>
    <div className="flex flex-col gap-2 items-center min-w-[80px]">
      <div className="flex justify-center items-center bg-neutral-2 rounded-2xl w-10 h-10">
        <Clock color='rgb(var(--tw-color-primary-500))' />
      </div>
      <div>
        {time} Min
      </div>
    </div>
    <div className="flex flex-col gap-2 items-center min-w-[80px]">
      <div className="flex justify-center items-center bg-neutral-2 rounded-2xl w-10 h-10">
        <Cutlery color='rgb(var(--tw-color-primary-500))' />
      </div>
      <div>
        {servings} {servings === 1 ? "Persona" : "Personas"}
      </div>
    </div>
  </div>
);

RecipeFeatures.displayName = 'RecipeFeatures';
